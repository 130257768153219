<template>
  <div data-app>
    <b-alert :show="successAlert.show" variant="success">
      {{ successAlert.text }}
    </b-alert>
    <b-alert :show="warningAlert.show" variant="danger">
      {{ warningAlert.text }}
    </b-alert>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="currencyCollection"
        :permissions="permissions"
        :search="search"
        :sort-by="['name']"
        :footer-props="getDatatableFooterProps()"
        :items-per-page="getDatatableItemPerPage()"
        @click:row="handleClickItem"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <!-- <v-toolbar-title>My CRUD</v-toolbar-title>-->
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <!-- <v-spacer></v-spacer> -->
            <CurrencyModalForm
              :modalData="modalData"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
            ></CurrencyModalForm>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{
                  $t("TABLE.are_you_sure_delete")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete">{{
                    $t("TABLE.cancel")
                  }}</v-btn>
                  <v-btn
                    color="primary"
                    @click="deleteItemConfirm"
                    v-if="permissionCan('delete')"
                    >{{ $t("TABLE.ok") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="permissionCan('view')"
          >
            mdi-pencil
          </v-icon> -->
          <v-icon
            color="primary"
            small
            @click="deleteItem(item)"
            v-if="permissionCan('delete') && item.deletable"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import CurrencyModalForm from "./CurrencyModalForm";
import i18nService from "@/core/services/i18n.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
import {
  FETCH_CURRENCY,
  CURRENCY_COLLECTION,
} from "@/core/services/store/currency.module.js";

const INITAL_FORM_DATA = {
  edited: true,
  id: "",
  sign: "",
  exchange_rate: null,
};

export default {
  name: "Currency",
  components: { CurrencyModalForm },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("FORMS.currencies"),
      appLocale: i18nService.getActiveLanguage(),
      users: [],
      search: "",
      permissions: [],
      routePath: "/settings/currency/",
      successAlert: {
        show: false,
        text: "Success alert!",
      },
      warningAlert: {
        show: false,
        text: "Warning alert!",
      },
      headers: [
        {
          text: this.$t("TABLE.currency"),
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: this.$t("TABLE.sign"),
          value: "sign",
        },
        {
          text: this.$t("TABLE.exchange_rate"),
          value: "exchange_rate",
        },
        { text: this.$t("TABLE.actions"), value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        languages: i18nService.languages,
        selectedLocale: i18nService.languages[0],
        users: [],
        editedIndex: -1,
        editedItem: INITAL_FORM_DATA,
        defaultItem: INITAL_FORM_DATA,
      },
      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters([CURRENCY_COLLECTION]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions([FETCH_CURRENCY]),

    editItem(item) {
      ApiService.get("currency/" + item.id)
        .then(({ data }) => {
          this.modalData.editedIndex = this[CURRENCY_COLLECTION].indexOf(item);
          this.modalData.editedItem = Object.assign({}, data);
          this.modalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.editItem(item);
      }
    },

    deleteItem(item) {
      this.editedIndex = this[CURRENCY_COLLECTION].indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete("currency/" + this.editedItem.id)
        .then(({ data }) => {
          console.log(data);
          this.fetchCurrency();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = -1;
      this.modalData.editedItem = this.modalData.defaultItem;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    handleSaveModalForm(model) {
      if (this.modalData.editedIndex > -1) {
        ApiService.put("currency/" + model.id, model)
          .then(({ data }) => {
            console.log(data);
            this.fetchCurrency();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            this.warningAlert.text = error.message;
            this.warningAlert.show = true;
            console.log("Error!: ", error);
          });
      } else {
        //create model
        ApiService.post("currency", model)
          .then(({ data }) => {
            console.log(data);
            this.fetchCurrency();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "system.currency." + action
      );
    },
    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("currency") > -1
        );
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "currency" }]);
    this.fetchCurrency();
    this.setPermissions();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
